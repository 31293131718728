window.closeEventsHistory = function () {
    let detailsPanel = document.getElementById("DetailsPanel");
    let eventDetails = document.getElementById("EventsHistory");

    eventDetails.style.display = 'none';
    detailsPanel.style.display = 'none';

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
}

window.openEventsHistory = function () {
    let detailsPanel = document.getElementById("DetailsPanel");
    let eventDetails = document.getElementById("EventsHistory");

    detailsPanel.style.display = 'block';
    eventDetails.style.display = 'block';

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";

    fetchEventsHistory();
}

window.fetchEventsHistory = async function () {
    try {
        const response = await fetch(apiBaseURL + `events_history?password=${apiPassword}`);
        if (!response.ok) {
            throw new Error('Network error');
        }
        const historyResponse = await response.json();
        displayEventsHistory(historyResponse.data);
    } catch (error) {
        console.error('Failed to fetch events history:', error);
    }
}

function createActionElement(eventHistory) {
    const actionContainer = document.createElement('div');
    actionContainer.style.display = 'flex';
    actionContainer.style.justifyContent = 'space-between';

    const action = document.createElement('p');
    action.className = 'actionTitle';
    action.innerHTML = eventActionToName(eventHistory.action)
    actionContainer.appendChild(action);

    const actionDate = document.createElement('p');
    const relativeTimeFormatter = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });

    let diff = new Date(eventHistory.action_date * 1000) - Date.now();
    let unit = determineUnit(diff);
    let inUnit = toUnit(diff, unit);

    const formattedTime = relativeTimeFormatter.format(inUnit, unit);
    actionDate.innerHTML = formattedTime;
    actionContainer.appendChild(actionDate);

    return actionContainer;
}

function createHistoryElementForCreated(eventHistory) {
    const newEvent = eventHistory.new_event;
    const historyElement = document.createElement('div');
    historyElement.className = 'historyItem';

    const actionContainer = createActionElement(eventHistory);
    historyElement.appendChild(actionContainer);

    const newTitle = document.createElement('p');
    newTitle.className = "eventTitle";
    newTitle.innerHTML = newEvent.title;
    historyElement.appendChild(newTitle);

    const eventDate = createDateTitleElement(newEvent);
    historyElement.appendChild(eventDate);

    return historyElement;
}

function createHistoryElementForModified(eventHistory) {
    const oldEvent = eventHistory.old_event;
    const newEvent = eventHistory.new_event;
    const historyElement = document.createElement('div');
    historyElement.className = 'historyItem';

    const actionContainer = createActionElement(eventHistory);
    historyElement.appendChild(actionContainer);

    const titleContainer = document.createElement('p');
    titleContainer.className = "eventTitle";
    if (oldEvent.title !== newEvent.title) {
        const oldTitle = document.createElement('div');
        oldTitle.className = 'deleted';
        oldTitle.innerHTML = oldEvent.title;
        titleContainer.appendChild(oldTitle);
    }

    const newTitle = document.createElement('div');
    newTitle.className = 'modified';
    newTitle.innerHTML = newEvent.title;
    titleContainer.appendChild(newTitle);
    historyElement.appendChild(titleContainer);

    const newEventDate = createDateTitleElement(newEvent);
    historyElement.appendChild(newEventDate);

    const oldEventDate = createDateTitleElement(oldEvent);
    oldEventDate.classList.add('deleted');
    historyElement.appendChild(oldEventDate);

    const places = document.createElement('p');
    const placesContainer = document.createElement('div');
    if (JSON.stringify(oldEvent.places) !== JSON.stringify(newEvent.places)) {
        const oldPlacesContainer = document.createElement('div');
        oldPlacesContainer.className = 'horizontalContainer';
        oldEvent.places.forEach(function (place) {
            const placeElement = createPlaceElement(place);
            placeElement.style.textDecoration = 'line-through';
            oldPlacesContainer.appendChild(placeElement);
        });
        placesContainer.appendChild(oldPlacesContainer);

        const newPlacesContainer = document.createElement('div');
        newEvent.places.forEach(function (place) {
            const placeElement = createPlaceElement(place);
            newPlacesContainer.appendChild(placeElement);
        });
        placesContainer.appendChild(newPlacesContainer);
    }
    places.appendChild(placesContainer);
    historyElement.appendChild(places);

    if (oldEvent.description !== newEvent.description) {
        const oldDescription = document.createElement('div');
        oldDescription.className = 'deleted';
        oldDescription.innerHTML = `${oldEvent.description}`;
        historyElement.appendChild(oldDescription);

        const newDescription = document.createElement('div');
        newDescription.innerHTML = `${newEvent.description}`;
        historyElement.appendChild(newDescription);
    }

    const groups = document.createElement('p');
    const groupsContainer = document.createElement('div');
    if (JSON.stringify(oldEvent.groups) !== JSON.stringify(newEvent.groups)) {
        const oldGroupsContainer = document.createElement('div');
        oldGroupsContainer.className = 'horizontalContainer';
        oldEvent.groups.forEach(function (group) {
            const groupChip = createGroupChipElement(group);
            groupChip.style.textDecoration = 'line-through';
            oldGroupsContainer.appendChild(groupChip);
        });
        groupsContainer.appendChild(oldGroupsContainer);

        const newGroupsContainer = document.createElement('div');
        newEvent.groups.forEach(function (group) {
            const groupChip = createGroupChipElement(group);
            newGroupsContainer.appendChild(groupChip);
        });
        groupsContainer.appendChild(newGroupsContainer);
    }
    groups.appendChild(groupsContainer);
    historyElement.appendChild(groups);

    return historyElement;
}

function createHistoryElementForDeleted(eventHistory) {
    const oldEvent = eventHistory.old_event;
    const historyElement = document.createElement('div');
    historyElement.className = 'historyItem';

    const actionContainer = createActionElement(eventHistory);
    historyElement.appendChild(actionContainer);

    const title = document.createElement('p');
    title.classList.add('eventTitle', 'deleted');
    title.innerHTML = oldEvent.title;
    historyElement.appendChild(title);

    const eventDate = createDateTitleElement(oldEvent);
    eventDate.classList.add('deleted');
    historyElement.appendChild(eventDate);

    return historyElement;
}

displayEventsHistory = function (history) {
    const historyListContainer = document.getElementById('EventsHistoryList');
    historyListContainer.innerHTML = ''; // Clear previous history

    if (history.length === 0) {
        const noHistoryElement = document.createElement('p');
        noHistoryElement.innerHTML = 'Aucun historique d\'événements';
        noHistoryElement.style.marginTop = '8rem';
        noHistoryElement.style.textAlign = 'center';
        historyListContainer.appendChild(noHistoryElement);
        return;
    }

    history.forEach(eventHistory => {
        let historyElement;
        switch (eventHistory.action) {
            case 'created':
                historyElement = createHistoryElementForCreated(eventHistory);
                historyElement.onclick = onClickOnEvent;
                historyElement.dataset.event = JSON.stringify(eventHistory.new_event);
                break;
            case 'modified':
                historyElement = createHistoryElementForModified(eventHistory);
                historyElement.onclick = onClickOnEvent;
                historyElement.dataset.event = JSON.stringify(eventHistory.new_event);
                break;
            case 'deleted':
                historyElement = createHistoryElementForDeleted(eventHistory);
                break;
            default:
                break;
        }

        historyElement.className = 'historyItem';
        const divider = document.createElement('hr');
        historyElement.appendChild(divider);

        historyListContainer.appendChild(historyElement);
    });
}

function eventActionToName(eventAction) {
    switch (eventAction) {
        case 'created':
            return 'Événement ajouté';
        case 'modified':
            return 'Événement modifié';
        case 'deleted':
            return 'Événement supprimé';
        default:
            return '';
    }
}

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 4 * WEEK;
const YEAR = MONTH * 12;

function determineUnit(x) {
    x = Math.abs(x);
    if (x < MINUTE) return 'second';
    if (x < HOUR) return 'minute';
    if (x < DAY) return 'hour';
    if (x < WEEK) return 'day';
    if (x < MONTH) return 'week';
    if (x < YEAR) return 'month';
    return 'year';
}

function toUnit(x, unit) {
    if (unit === 'minute') return Math.round(x / MINUTE);
    if (unit === 'hour') return Math.round(x / HOUR);
    if (unit === 'day') return Math.round(x / DAY);
    if (unit === 'week') return Math.round(x / WEEK);
    if (unit === 'month') return Math.round(x / MONTH);
    if (unit === 'year') return Math.round(x / YEAR);
}
window.onClickOnEvent = function (clickEvent) {
    let eventElement = clickEvent.target;
    while (!eventElement.dataset.event) {
        eventElement = eventElement.parentElement;
    }

    let event = JSON.parse(eventElement.dataset.event);

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";

    let eventDetails = document.getElementById("EventDetails");
    eventDetails.style.display = 'block';

    let detailsPanel = document.getElementById("DetailsPanel");
    detailsPanel.style.display = 'block';

    let eventTitle = document.getElementById("EventTitle");
    eventTitle.innerHTML = event.title;

    let eventDate = document.getElementById("EventDate");
    eventDate.innerHTML = "";
    eventDate.appendChild(createDateTitleElement(event))

    if (event.places.length > 0) {
        let placesContainer = document.getElementById("EventPlaces");
        placesContainer.innerHTML = "";
        placesContainer.className = "chipsContainer";
        placesContainer.appendChild(createBulletElement());
        event.places.forEach(function (place) {
            const placeElement = createPlaceElement(place);
            placesContainer.appendChild(placeElement);
        });
    }

    let eventDescription = document.getElementById("EventDescription");
    eventDescription.innerHTML = event.description;

    let groupsContainer = document.getElementById("EventGroups");
    groupsContainer.innerHTML = "";
    groupsContainer.className = "chipsContainer";
    event.groups.forEach(function (group) {
        const groupChip = createGroupChipElement(group);
        groupsContainer.appendChild(groupChip);
    });
}

window.closeEventDetails = function () {
    let detailsPanel = document.getElementById("DetailsPanel");
    let eventDetails = document.getElementById("EventDetails");

    eventDetails.style.display = 'none';
    detailsPanel.style.display = 'none';

    let body = document.getElementsByTagName("body")[0];
    body.style.overflow = "auto";
}